<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <!-- <div class="cons">
        <div class="zuozhe">王老五</div>
        <div class="zuozhe-lsit">
          <div class="xswx-title">作者相关文献</div>
          <div class="xswx-ul">
            <div v-for="(item, key) in xswzlistnew" :key="key">
              <router-link
                target="_blank"
                :to="{ name: '/xkzs/delwz', query: { id: item.Id } }"
              >
                <div class="xswx-li">
                  <div>{{ item.arttitle }}</div>
                  <div class="xswx-li-zz">{{ item.writer }}-{{ item.org }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div>
        <video width="600" height="480" loop="loop" controls="controls" poster="https://ts1.cn.mm.bing.net/th/id/R-C.3e42ad7c7744a7a612bfbaa480405611?rik=eX46Dun%2fFF1aDg&riu=http%3a%2f%2fpic.bizhi360.com%2fbbpic%2f25%2f6125.jpg&ehk=ukEQFHgNgClnZxjkosi%2bP3OoaZicxvcitCFcgJISxww%3d&risl=&pid=ImgRaw&r=0">
          <source src="https://cmsvod.cbg.cn/2024/01/05/sd_62f50761-739e-4af4-a999-11395d541eb9.mp4?key=810a1dc3ffcda43f1ba7bb3547ab79be&time=202401241429" type="video/mp4" />
        </video>
      </div> -->
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 链接参数
let name = decodeURIComponent(router.currentRoute.value.query.data);
console.log(name);

let xswzlistnew = reactive([
  { Id: 1001, arttitle: "阿看见双层巴士看见你", writer: "王扎啤", org: "擦拭" },
]);
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}

.zuozhe-lsit {
  margin-top: 15px;
  font-size: 14px;
  padding: 10px 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  padding: 20px;
}

.xswx-title {
  font-weight: bold;
  padding-bottom: 10px;
}
.xswx-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  line-height: 20px;
  padding: 5px 0;
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
}
.xswx-li:hover {
  background: #f2f2f2;
}
.xswx-li-zz {
  max-width: 400px;
  height: 20px;
  line-height: 20px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xswx-ul a:link,
.xswx-ul a:visited {
  color: #333;
  text-decoration: none;
}
</style>
